$(function() {
    var header = $("#main_navbar");
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= 35) {
            header.addClass("shadow-sm");
        }
        else {
            header.removeClass("shadow-sm");
        }
    });
});

$('form').one('submit', function() {
    $(this).find('button[type="submit"]').attr('disabled','disabled');
});

var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
})

////////////////

let cookieBanner = document.getElementById("cookieBanner");

document.getElementById("GotItCookie").addEventListener("click", () => {
    let d = new Date();
    // d.setMinutes(2 + d.getMinutes());
    d.setFullYear(d.getFullYear() + 1);

    document.cookie = 'CookieConsent=OK;expires='+ d.toUTCString() +';path=/';

    cookieBanner.classList.add("hide_cookieBanner");
    cookieBanner.classList.remove("show_cookieBanner");
});

const checkCookie = () => {

    /*
    let input = document.cookie.split("=");

    if(input[0] == "CookieConsent"){
        cookieBanner.classList.add("hide_cookieBanner");
        cookieBanner.classList.remove("show_cookieBanner");
    }else{
        cookieBanner.classList.add("show_cookieBanner");
        cookieBanner.classList.remove("hide_cookieBanner");
    }
    */

    ////////

    function getCookie(name) {
        var dc = document.cookie;
        var prefix = name + "=";
        var begin = dc.indexOf("; " + prefix);
        if (begin == -1) {
            begin = dc.indexOf(prefix);
            if (begin != 0) return null;
        }
        else
        {
            begin += 2;
            var end = document.cookie.indexOf(";", begin);
            if (end == -1) {
            end = dc.length;
            }
        }
        // because unescape has been deprecated, replaced with decodeURI
        //return unescape(dc.substring(begin + prefix.length, end));
        return decodeURI(dc.substring(begin + prefix.length, end));
    }


    let myCookie = getCookie("CookieConsent");

    if (myCookie == null) {
        // do cookie doesn't exist stuff;
        cookieBanner.classList.add("show_cookieBanner");
        cookieBanner.classList.remove("hide_cookieBanner");
    }
    else {
        // do cookie exists stuff
        cookieBanner.classList.add("hide_cookieBanner");
        cookieBanner.classList.remove("show_cookieBanner");
    }
};

window.onload = () => {
    setTimeout(()=>{
        checkCookie();
    }, 500);
};

////////////////
